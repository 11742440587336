import Vue from "vue";
import Vuetify from "vuetify/lib";
import ru from "vuetify/es5/locale/ru";
import VuetifyConfirm from "vuetify-confirm";

// import colors from "vuetify/lib/util/colors";
// (Optional) import 'vuetify-datetime-picker/src/stylus/main.styl'

Vue.use(Vuetify);

// vuetify setting obj
const vuetify = new Vuetify({
  lang: {
    locales: { ru },
    current: "ru"
  },
  theme: {
    options: {
      customProperties: true
    },
    dark: false,
    themes: {
      light: {
        // primary: "#7676A7", // indigo
        primary: "#DDDB00", // blueGray #435b71 // yellow #d9e021 // #bbca00 //#DDDB00 // #EFF400
        secondary: "#808AFF", // blue // #6200EE
        info: "#2F2D2D", // dark
        accent: "#3751FF", //#01A299 // #C2185B
        error: "#e0217a",
        warning: "#E2C321",
        success: "#43DE79",
        agency: "#DA3131",
        client: "#3193DA",
        brand: "#31DABB"
      },
      dark: {
        primary: "#E5332A",
        secondary: "#424242",
        accent: "#82B1FF",
        error: "#751010",
        info: "#730019",
        success: "#4CAF50",
        warning: "#FFC107"
      }
    }
  }
});
Vue.use(VuetifyConfirm, {
  vuetify,
  buttonTrueText: "Ок",
  buttonFalseText: "Отмена",
  color: "warning",
  icon: "mdi-alert",
  title: "Подтвердите действие",
  width: 350,
  property: "$confirm",
  persistent: true
});
export default vuetify;
