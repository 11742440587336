<template>
  <v-main class="login-bg">
    <v-slide-x-transition>
      <router-view class="auth-content"></router-view>
    </v-slide-x-transition>
    <div class="wave-container">
      <vue-wavify
        fill="#EFF400"
        mask="url(#mask)"
        :points="7"
        :amplitude="200"
        :speed="0.09"
        :height="90"
      >
      </vue-wavify>
    </div>
    <div class="yellow-bottom-wave-block"></div>
  </v-main>
</template>
<script>
import VueWavify from "vue-wavify";

export default {
  components: {
    VueWavify
  },
  data() {
    return {
      drawer: null
    };
  }
};
</script>
