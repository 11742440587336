/* eslint-disable */

//orders store namespace

import {
  CREATE_ORDER,
  DELETE_ORDER,
  REPAIR_ORDER,
  SEARCH_ORDERS,
  GET_ORDERS,
  GET_ORDER,
  UPDATE_ORDER,
  UPDATE_ORDER_META,
  APPROVE_ORDER,
  UNAPPROVE_ORDER,
  EDIT_ORDER_STATUS,
  SET_ORDER_STATUS,
  UPLOAD_ORDER_FILE,
  GET_ORDER_FILES,
  DOWNLOAD_ORDER_FILE,
  DELETE_ORDER_FILE,
  GET_ORDER_STATUSES,
  GET_ORDERS_BY_BRAND,
  EDIT_KEEPER_STATUS_FOR_ORDER,
  EDIT_WATCHER_STATUS_FOR_ORDER,
  GET_ORDER_PRIORITIES,
  GET_PRICE,
  GENERATE_XLS,
  CHECK_EXIST_XLS,
  DOWNLOAD_ORDER_XLS,
  GET_DEMOGRAPHY, UPLOAD_XLS_MANUAL, GET_FORMULAS
} from "../const/orders";
import { HTTP_POST, HTTP_GET, HTTP_DELETE, HTTP_PATCH } from "../const/request";
import dbg from "@/plugins/dbg";
// const serializeOrderObject = (order) => {
//    order.calc_raw = order.calc;
//    return order
// }

////////////////////////////////////////////////////// STATE
function parseServerOrder(data) {
  let currentOrder = {};
  dbg('data',data);
  let calc_result = data.calc_result;
  if(!data.calc_result){
    data.calc_result = {
       budget_percent: 100,
      ctr: 0,
      frequency: 0,
      max_frequency: 3.7,
      min_price: 0,
      name: "rtb",
      price: 0,
      reach: 0,
      shows: 0,
    }
  }
  calc_result.price = +data.price
  calc_result.min_price = +data.min_price
  data.flights = data.flights.map(a => {
    return {
      date_start: a.start,
      date_end: a.end
    };
  });
  if (data.flights.length) {
    data.dates = {
      from: data.flights[0].date_start,
      to: data.flights[data.flights.length - 1].date_end
    };
  } else {
    data.dates = { from: "", to: "" };
  }
  currentOrder = JSON.parse(JSON.stringify(data))

  currentOrder.calc_result = calc_result;
  return currentOrder;
}

////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////
const orders = {
  state: () => ({
    formulas: {

    },
    orderSyncLoading: false,
    orders: [],
    excelExist: false,
    ordersHeaders: [
      {
        visible: true,
        width: "40%",
        text: "Название",
        align: "start",
        value: "name"
      },
      {
        visible: true,
        text: "Статус",
        align: "start",
        value: "status"
      },
      {
        visible: false,
        text: "Агентство",
        align: "start",
        value: "brand.client.agency.name"
      },
      {
        visible: false,
        text: "Клиент",
        align: "start",
        value: "brand.client.name"
      },
      {
        visible: false,
        text: "Бренд",
        align: "start",
        value: "brand.name"
      },
      {
        visible: false,
        text: "Созданные",
        align: "start",
        width: "200px",
        value: "created_at"
      },
      {
        visible: true,
        text: "Крайний срок",
        align: "start",
        width: "200px",
        value: "calc_deadline"
      },
      {
        visible: false,
        text: "Обновленные",
        align: "start",
        width: "200px",
        value: "updated_at"
      },

      {
        visible: false,
        text: "Удаленные",
        align: "start",
        width: "200px",
        value: "deleted_at"
      },
      {
        visible: false,
        text: "Завершенные",
        align: "start",
        width: "200px",
        value: "done_at"
      },
      {
        visible: false,
        text: "Ответственные",
        align: "start",
        width: "200px",
        value: "keepers"
      },
      {
        visible: true,
        text: "Опции",
        align: "start",
        value: "options",
        sortable: false
      }
    ],
    currentOrder: {
      calc_result: {}
    },
    frequencies: {
      rtb: 417,
      vpaid: 288,
      teaser: 15
    },
    orderFiles: [],
    statuses: [],
    priorities: [],
    // Дефолтные значения калькулятора
    defaultCalcResult: {

    },
    prices: {
      retail: 0,
      base: 0
    },
    allDemography: [],
    gender: ""
  }),
  ////////////////////////////////////////////////////// ACTIONS
  ////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////
  actions: {
    async [EDIT_KEEPER_STATUS_FOR_ORDER]({ dispatch }, { id, verb, user }) {
      return await dispatch(
        HTTP_PATCH,
        {
          method: EDIT_KEEPER_STATUS_FOR_ORDER,
          // body: data,
          params: { id, verb, user },
          no_commit: true,
          namespace: "Orders"
        },
        { root: true }
      );
    },
    async [EDIT_WATCHER_STATUS_FOR_ORDER]({ dispatch }, { id, verb, user }) {
      return await dispatch(
        HTTP_PATCH,
        {
          method: EDIT_WATCHER_STATUS_FOR_ORDER,
          // body: data,
          params: { id, verb, user },
          no_commit: true,
          namespace: "Orders"
        },
        { root: true }
      );
    },
    async [SEARCH_ORDERS]({ dispatch }, key) {
      return await dispatch(
        HTTP_GET,
        {
          method: SEARCH_ORDERS,
          withTrashed: true,
          replace: { key },
          namespace: "Orders"
        },

        { root: true }
      );
    },
    async [GET_ORDERS]({ dispatch }, query) {
      return await dispatch(
        HTTP_GET,
        {
          method: GET_ORDERS,
          replace: { query },
          withTrashed: true,
          namespace: "Orders",
          withCancelability: true
        },

        { root: true }
      );
    },
    async [GET_FORMULAS]({ dispatch },) {
      return await dispatch(
        HTTP_GET,
        {
          method: GET_FORMULAS,
          namespace: "Orders",
        },

        { root: true }
      );
    },
    async [GET_ORDERS_BY_BRAND]({ dispatch, commit }, brand) {
      commit(GET_ORDERS_BY_BRAND, { data: [] });
      return await dispatch(
        HTTP_GET,
        {
          withTrashed: true,
          method: GET_ORDERS_BY_BRAND,
          replace: { brand },
          namespace: "Orders"
        },

        { root: true }
      );
    },
    async [GENERATE_XLS]({ dispatch }, { id }) {
      return await dispatch(
        HTTP_POST,
        {
          method: GENERATE_XLS,
          params: { id },
          no_commit: true,
          namespace: "Orders"
        },
        { root: true }
      );
    },
    async [CHECK_EXIST_XLS]({ dispatch }, { id }) {
      return await dispatch(
        HTTP_GET,
        {
          method: CHECK_EXIST_XLS,
          namespace: "Orders",
          replace: { id }
        },
        { root: true }
      );
    },

    async [APPROVE_ORDER]({ dispatch }, { id, type }) {
      return await dispatch(
        HTTP_PATCH,
        {
          method: APPROVE_ORDER,
          // body: data,
          params: { id, type },
          no_commit: true,
          namespace: "Orders"
        },
        { root: true }
      );
    },
    async [EDIT_ORDER_STATUS]({ dispatch }, { id, type }) {
      return await dispatch(
        HTTP_PATCH,
        {
          method: EDIT_ORDER_STATUS,
          // body: data,
          params: { id, type },
          no_commit: true,
          namespace: "Orders"
        },
        { root: true }
      );
    },
    async [SET_ORDER_STATUS]({ dispatch }, { id, type, data }) {
      console.log('data')
      return await dispatch(
        HTTP_PATCH,
        {
          method: SET_ORDER_STATUS,
          body: data,
          params: { id },
          no_commit: true,
          namespace: "Orders"
        },
        { root: true }
      );
    },
    async [UNAPPROVE_ORDER]({ dispatch }, { id, type }) {
      return await dispatch(
        HTTP_PATCH,
        {
          method: UNAPPROVE_ORDER,
          // body: data,
          params: { id, type },
          no_commit: true,
          namespace: "Orders"
        },
        { root: true }
      );
    },

    async [UPLOAD_XLS_MANUAL]({ dispatch }, { id, data }) {
      return await dispatch(
        HTTP_POST,
        {
          method: UPLOAD_XLS_MANUAL,
          formData: data,
          params: { id },
          no_commit: true,
          namespace: "Orders"
        },
        { root: true }
      );
    },
    async [UPLOAD_ORDER_FILE]({ dispatch }, { id, data }) {
      return await dispatch(
        HTTP_POST,
        {
          method: UPLOAD_ORDER_FILE,
          formData: data,
          params: { id },
          no_commit: true,
          namespace: "Orders"
        },
        { root: true }
      );
    },
    async [CREATE_ORDER]({ dispatch }, { id, data }) {
      //data = serializeOrderObject(data)
      return await dispatch(
        HTTP_POST,
        {
          method: CREATE_ORDER,
          params: { id },
          body: data,
          no_commit: true,
          namespace: "Orders"
        },
        { root: true }
      );
    },

    async [GET_ORDER]({ dispatch }, { id }) {
      return await dispatch(
        HTTP_GET,
        {
          method: GET_ORDER,
          namespace: "Orders",
          replace: { id }
        },
        { root: true }
      );
    },
    async [GET_PRICE]({ dispatch }, { id, recalcType, originalType, value }) {
      return await dispatch(
        HTTP_GET,
        {
          method: GET_PRICE,
          namespace: "Orders",
          replace: { id, recalcType, originalType, value },
          params: recalcType
        },
        { root: true }
      );
    },
    async [GET_ORDER_STATUSES]({ dispatch }) {
      return await dispatch(
        HTTP_GET,
        {
          method: GET_ORDER_STATUSES,
          namespace: "Orders"
        },
        { root: true }
      );
    },
    async [GET_ORDER_PRIORITIES]({ dispatch }) {
      return await dispatch(
        HTTP_GET,
        {
          method: GET_ORDER_PRIORITIES,
          namespace: "Orders"
        },
        { root: true }
      );
    },
    async [DOWNLOAD_ORDER_XLS]({ dispatch }, { id }) {
      return await dispatch(
        HTTP_GET,
        {
          method: DOWNLOAD_ORDER_XLS,
          namespace: "Orders",
          replace: { id },
          no_commit: true,
          download: true
        },
        { root: true }
      );
    },
    async [DOWNLOAD_ORDER_FILE]({ dispatch }, { id }) {
      return await dispatch(
        HTTP_GET,
        {
          method: DOWNLOAD_ORDER_FILE,
          namespace: "Orders",
          replace: { id },
          no_commit: true,
          download: true
        },
        { root: true }
      );
    },
    async [GET_ORDER_FILES]({ dispatch }, { id }) {
      return await dispatch(
        HTTP_GET,
        {
          method: GET_ORDER_FILES,
          namespace: "Orders",
          replace: { id }
        },
        { root: true }
      );
    },
    async [DELETE_ORDER]({ dispatch }, { id }) {
      return await dispatch(
        HTTP_DELETE,
        {
          method: DELETE_ORDER,
          params: { id },
          no_commit: true,
          namespace: "Orders"
        },
        { root: true }
      );
    },
    async [REPAIR_ORDER]({ dispatch }, { id }) {
      return await dispatch(
        HTTP_PATCH,
        {
          method: REPAIR_ORDER,
          params: { id },
          no_commit: true,
          namespace: "Orders"
        },
        { root: true }
      );
    },
    async [DELETE_ORDER_FILE]({ dispatch }, { id }) {
      return await dispatch(
        HTTP_DELETE,
        {
          method: DELETE_ORDER_FILE,
          params: { id },
          no_commit: true,
          namespace: "Orders"
        },
        { root: true }
      );
    },
    async [UPDATE_ORDER]({ dispatch }, { id, data }) {
      //data = serializeOrderObject(data)
      return await dispatch(
        HTTP_PATCH,
        {
          method: UPDATE_ORDER,
          params: { id },
          body: data,
          no_commit: true,
          namespace: "Orders"
        },
        { root: true }
      );
    },

    async [UPDATE_ORDER_META]({ dispatch }, { id, data }) {
      //data = serializeOrderObject(data)
      return await dispatch(
        HTTP_PATCH,
        {
          method: UPDATE_ORDER_META,
          params: { id },
          body: data,
          no_commit: true,
          namespace: "Orders"
        },
        { root: true }
      );
    },

    async [GET_DEMOGRAPHY]({ dispatch }) {
      return await dispatch(
        HTTP_GET,
        {
          method: GET_DEMOGRAPHY,
          namespace: "Orders"
        },

        { root: true }
      );
    }
  },
  ////////////////////////////////////////////////////// MUTATIONS
  //////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////
  mutations: {
    [GET_FORMULAS](state,{data}){
      state.formulas = data
    },
    [CHECK_EXIST_XLS](state, { data }) {
      state.excelExist = data.file_exist;
    },
    [GET_ORDERS](state, { data }) {
      state.orders = data;
    },
    [GET_ORDERS_BY_BRAND](state, { data }) {
      state.orders = data;
    },
    [SEARCH_ORDERS](state, { data }) {
      state.orders = data;
    },
    // getOrder, получение брони
    [GET_ORDER](state, { data }) {
      state.currentOrder = parseServerOrder( data);
    },

    [GET_PRICE](state, { data, params: price }) {
      state.prices[price] = data.result;
    },
    [GET_ORDER_STATUSES](state, { data }) {
      state.statuses = data;
    },
    [GET_ORDER_PRIORITIES](state, { data }) {
      state.priorities = data;
    },
    [GET_ORDER_FILES](state, { data }) {
      state.orderFiles = data;
    },

    [GET_DEMOGRAPHY](state, { data }) {
      state.allDemography = Object.values(data);
    },

    // Костыль для предотварщения обновления данных при загрузке страницы
    setOrderSyncLoading(state, bool) {
      state.orderSyncLoading = bool;
    },
    resetPrevOrderStoreValues(state) {
      state.excelExist = false;
      state.currentOrder = {
        calc_result: { },
        calc_raw: {}
      };
      state.orderFiles = [];
      state.statuses = [];
      state.priorities = [];
      state.defaultCalcResult = {
      };
      state.prices = {
        retail: 0,
        base: 0
      };
      state.allDemography = [];
      state.gender = "";
    },
    // data === calc_result

    // Ставит дефолтные значения калькулятора( высчитаные из прогнозатора)
    setDefaultCalcResult(state, { data, calc }) {

      let formula = JSON.parse(JSON.stringify(state.formulas))
      // Убираем доллары из формул
      for (const prop in formula) {
        if(typeof formula[prop] === "string"){
          formula[prop] = formula[prop].replaceAll('$','')
        }
      }
      dbg('formula',formula)
      dbg('data', calc)
      const
        shows = data.shows || 0,
        price = data.price || 0,
        ctr = data.ctr || 0,
        min_price = data.min_price || 0,
        frequency = data.frequency || data.max_frequency || 0;

      const vtr = +eval(formula.vtr) || 0;
      const views = Math.ceil(+eval(formula.views)) || 0;
      const reach = Math.ceil(+eval(formula.reach)) || 0;
      const clicks = Math.ceil(+eval(formula.clicks)) || 0;
      //максимальный бюджет
      let budget = 0;
      // show === cpm
      if (calc.format === "vpaid" && calc.bidType === "show") {
        budget = +eval(formula.budgetVpaidCPM) || 0;
      }
      else if (calc.format === "vpaid" && calc.bidType === "complete") {
        budget = +eval(formula.budgetVpaidCPV) || 0;
      }
      else if (calc.format === "rtb" && calc.bidType === "show") {
        budget = +eval(formula.budgetRtbCPM) || 0;
      }
      else if (calc.format === "rtb" && calc.bidType === "click") {
        budget = +eval(formula.budgetTeaserCPC) || 0;
      }
      if (calc.format === "teaser" && calc.bidType === "show") {
        budget = +eval(formula.budgetRtbCPM) || 0;
      }
      if (calc.format === "teaser" && calc.bidType === "click") {
        budget = +eval(formula.budgetTeaserCPC) || 0;
      }
      budget = Math.ceil(budget);
      const cpc = +eval(formula.cpc) || 0;
      const cpm = +eval(formula.cpm) || 0;
      const cpt = +eval(formula.cpt) || 0;
      const cpv = +eval(formula.cpv) || 0;

      state.defaultCalcResult = {
        name: data.name,
        shows,
        price,
        ctr,
        frequency,
        vtr,
        views,
        reach,
        clicks,
        budget,
        cpc,
        cpm,
        cpt,
        cpv,
        min_price,

      }

    },
    updateOrderHeaders(state, data) {
      state.ordersHeaders = data;

      localStorage.setItem("orderHeaders", JSON.stringify(data));
    }
  },
  ////////////////////////////////////////////////////// GETTERS
  //////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////
  getters: {
    getOrderSyncLoading: state => state.orderSyncLoading,
    getExcelExist: state => state.excelExist,
    getHeaders: state =>
      state.ordersHeaders.filter(header => header.visible === true),
    getAllHeaders: state => state.ordersHeaders,
    getOrders: state => state.orders,
    getStatuses: state => state.statuses,
    getOrder: state => state.currentOrder,
    getCurrentOrderId: state => state.currentOrder.id,
    getOrderStatus: state => state.currentOrder?.status.key ?? "",
    getOrderCalc: state => state.currentOrder?.calc_result ?? {},
    getAvailableActions: state => state.currentOrder?.actions ?? {},
    getCalcResult: state => state.currentOrder?.calc_result ?? {},
    getPrices: state => state.prices,
    getFormulas: state => state.formulas,
    orderWasDeleted: state => (state.currentOrder.deleted_at ? true : false),
    getAllDemography: state => state.allDemography,
    getCurrentGender: state => state.gender,
    getDefaultCalcResult: state => state.defaultCalcResult,
    ////////////////////////////
    getOrderStatuses: state =>
      Array.from(new Set(state.orders.map(({ status }) => status)))
  },
  namespaced: true
};

export default orders;
