/* eslint-disable */
export const GET_ORDERS = "GET_ORDERS";
export const CREATE_ORDER = "CREATE_ORDER";
export const GET_ORDER = "GET_ORDER";
export const DELETE_ORDER = "DELETE_ORDER";
export const BLOCK_ORDER = "BLOCK_ORDER";
export const UNBLOCK_ORDER = "UNBLOCK_ORDER";

export const UPDATE_ORDER_META = "UPDATE_ORDER_META";

export const UPDATE_ORDER = "UPDATE_ORDER";

export const APPROVE_ORDER = "APPROVE_ORDER";
export const UNAPPROVE_ORDER = "UNAPPROVE_ORDER";
// изменить статус на inprogress/approving/done
export const EDIT_ORDER_STATUS = "EDIT_ORDER_STATUS";

export const SEARCH_ORDERS = "SEARCH_ORDERS";

export const GET_ORDERS_BY_BRAND = "GET_ORDERS_BY_BRAND";

export const REPAIR_ORDER = "REPAIR_ORDER";

export const EDIT_WATCHER_STATUS_FOR_ORDER = "EDIT_WATCHER_STATUS_FOR_ORDER";

export const EDIT_KEEPER_STATUS_FOR_ORDER = "EDIT_KEEPER_STATUS_FOR_ORDER";

export const UPLOAD_ORDER_FILE = "UPLOAD_ORDER_FILE";

export const DOWNLOAD_ORDER_FILE = "DOWNLOAD_ORDER_FILE";

export const GET_ORDER_FILES = "GET_ORDER_FILES";

export const DELETE_ORDER_FILE = "DELETE_ORDER_FILE";

export const GET_ORDER_STATUSES = "GET_ORDER_STATUSES";
export const SET_ORDER_STATUS = "SET_ORDER_STATUS";
export const GET_ORDER_PRIORITIES = "GET_ORDER_PRIORITIES";



export const GET_PRICE = "GET_PRICE";
export const GET_FORMULAS = "GET_FORMULAS";

export const DOWNLOAD_ORDER_XLS = "DOWNLOAD_ORDER_XLS";
export const CHECK_EXIST_XLS = "CHECK_EXIST_XLS";
export const GENERATE_XLS = "GENERATE_XLS";

// для загрузки ПРогноза юзером(аккаунтом)
export const UPLOAD_XLS_MANUAL = "UPLOAD_XLS_MANUAL";
// для скачивания ПРогноза юзером(Клиентом)
export const DOWNLOAD_XLS_EXAMPLE = "DOWNLOAD_XLS_EXAMPLE";


export const GET_DEMOGRAPHY = "GET_DEMOGRAPHY";
