//brands store namespace

import {
  CREATE_BRAND,
  DELETE_BRAND,
  GET_BRANDS,
  GET_BRAND,
  UPDATE_BRAND,
  GET_BRANDS_BY_CLIENT
} from "../const/brands";
import { HTTP_POST, HTTP_GET, HTTP_DELETE, HTTP_PATCH } from "../const/request";

const brands = {
  state: () => ({
    brands: [],
    currentBrand: {}
  }),
  actions: {
    async [GET_BRANDS]({ dispatch }) {
      return await dispatch(
        HTTP_GET,
        { method: GET_BRANDS, withTrashed: true, namespace: "Brands" },
        { root: true }
      );
    },
    async [GET_BRANDS_BY_CLIENT]({ dispatch }, client) {
      return await dispatch(
        HTTP_GET,
        {
          withTrashed: true,
          method: GET_BRANDS_BY_CLIENT,
          replace: { client },
          namespace: "Brands"
        },
        { root: true }
      );
    },
    async [CREATE_BRAND]({ dispatch }, data) {
      return await dispatch(
        HTTP_POST,
        {
          method: CREATE_BRAND,
          body: data,
          no_commit: true,
          namespace: "Brands"
        },
        { root: true }
      );
    },
    async [GET_BRAND]({ dispatch }, { id }) {
      return await dispatch(
        HTTP_DELETE,
        {
          method: GET_BRAND,
          params: { id },
          namespace: "Brands"
        },
        { root: true }
      );
    },
    async [DELETE_BRAND]({ dispatch }, { id }) {
      return await dispatch(
        HTTP_DELETE,
        {
          method: DELETE_BRAND,
          params: { id },
          no_commit: true,
          namespace: "Brands"
        },
        { root: true }
      );
    },
    async [UPDATE_BRAND]({ dispatch }, { id, data }) {
      return await dispatch(
        HTTP_PATCH,
        {
          method: UPDATE_BRAND,
          params: { id },
          body: data,
          no_commit: true,
          namespace: "Brands"
        },
        { root: true }
      );
    }
  },
  mutations: {
    [GET_BRANDS](state, { data }) {
      state.brands = data;
    },
    [GET_BRANDS_BY_CLIENT](state, { data }) {
      state.brands = data;
    },
    [GET_BRAND](state, { data }) {
      state.currentBrand = data;
    }
  },
  getters: {
    // getSomeModuleValue: state => state.someModuleValue
  },
  namespaced: true
};
export default brands;
