export const GET_TAGS = "GET_TAGS";
export const CREATE_TAG = "CREATE_TAG";
export const GET_TAG = "GET_TAG";
export const DELETE_TAG = "DELETE_TAG";
export const BLOCK_TAG = "BLOCK_TAG";
export const UNBLOCK_TAG = "UNBLOCK_TAG";
export const UPDATE_TAG = "UPDATE_TAG";
export const SEND_BUG_REPORT = "SEND_BUG_REPORT";

export const GET_GEOTAXONOMY = "GET_GEOTAXONOMY";
