// import { someModule } from "module";

import {
  AUTH_ABOUT_ME,
  AUTH_LOGIN,
  AUTH_LOGOUT,
  AUTH_PASSWORD_RESET,
  AUTH_PASSWORD_CHANGE,
  AUTH_PASSWORD_SET
} from "./auth";
import {
  CREATE_BRAND,
  DELETE_BRAND,
  GET_BRAND,
  GET_BRANDS,
  UPDATE_BRAND,
  GET_BRANDS_BY_CLIENT
} from "./brands";
import {
  CREATE_AGENCY,
  DELETE_AGENCY,
  GET_AGENCIES,
  GET_AGENCY,
  UPDATE_AGENCY
} from "./agencies";
import {
  CREATE_TAG,
  DELETE_TAG,
  GET_TAGS,
  GET_TAG,
  UPDATE_TAG,
  GET_GEOTAXONOMY,
  SEND_BUG_REPORT
} from "./helpers";
import {
  GET_ORDER_PRIORITIES,
  CREATE_ORDER,
  DELETE_ORDER,
  REPAIR_ORDER,
  GET_ORDERS,
  GET_ORDER,
  UPDATE_ORDER,
  APPROVE_ORDER,
  UNAPPROVE_ORDER,
  EDIT_ORDER_STATUS,
  UPDATE_ORDER_META,
  EDIT_KEEPER_STATUS_FOR_ORDER,
  SEARCH_ORDERS,
  GET_ORDERS_BY_BRAND,
  EDIT_WATCHER_STATUS_FOR_ORDER,
  //
  UPLOAD_ORDER_FILE,
  GET_ORDER_FILES,
  DOWNLOAD_ORDER_FILE,
  DELETE_ORDER_FILE,
  //
  GET_ORDER_STATUSES,
  //
  GET_PRICE,
  DOWNLOAD_ORDER_XLS,
  CHECK_EXIST_XLS,
  GENERATE_XLS,
  GET_DEMOGRAPHY,
  UPLOAD_XLS_MANUAL,
  DOWNLOAD_XLS_EXAMPLE,
  GET_FORMULAS,
  SET_ORDER_STATUS
} from "./orders";
import {
  CREATE_CLIENT,
  DELETE_CLIENT,
  GET_CLIENT,
  GET_CLIENTS,
  UPDATE_CLIENT,
  GET_CLIENTS_BY_AGENCY
} from "./clients";
import {
  CREATE_USER,
  GET_USERS,
  GET_USER,
  UPDATE_USER,
  DELETE_USER,
  REPAIR_USER,
  GET_FILTERED_USERS,
  GET_USERS_TO_KEEPERS
} from "./users";
import { GET_ROLES, GET_ROLE } from "./roles";

const roles = {
  [GET_ROLES]: "user-types",
  [GET_ROLE]: "roles/:id:"
};
const auth = {
  [AUTH_LOGIN]: "login",
  [AUTH_LOGOUT]: "logout",
  [AUTH_ABOUT_ME]: "me",
  [AUTH_PASSWORD_RESET]: "password-reset",
  [AUTH_PASSWORD_CHANGE]: "password-change",
  [AUTH_PASSWORD_SET]: "password-set"
};
const users = {
  [GET_USERS]: "users",
  [GET_FILTERED_USERS]: "users?:query:",
  [CREATE_USER]: "users",
  [GET_USER]: "users/:id:",
  [UPDATE_USER]: "users/:id:",
  [DELETE_USER]: "users/:id:/to-trash",
  [REPAIR_USER]: "users/:id:/from-trash",
  [GET_USERS_TO_KEEPERS]: "users/:id:/usersToKeepers"
};
const agencies = {
  [GET_AGENCIES]: "agencies",
  [CREATE_AGENCY]: "agencies",
  [GET_AGENCY]: "agencies/:id:",
  [UPDATE_AGENCY]: "agencies/:id:",
  [DELETE_AGENCY]: "agencies/:id:"
};
const helpers = {
  [GET_TAGS]: "tags",
  [CREATE_TAG]: "tags",
  [GET_TAG]: "tags/:id:",
  [UPDATE_TAG]: "tags/:id:",
  [DELETE_TAG]: "tags/:id:",
  [SEND_BUG_REPORT]: "error",
  [GET_GEOTAXONOMY]: "geo"
};
const clients = {
  [GET_CLIENTS]: "clients",
  [CREATE_CLIENT]: "clients",
  [GET_CLIENT]: "clients/:id:",
  [UPDATE_CLIENT]: "clients/:id:",
  [DELETE_CLIENT]: "clients/:id:",
  [GET_BRANDS_BY_CLIENT]: "clients/:client:/brands"
};
const brands = {
  [GET_BRANDS]: "brands",
  [CREATE_BRAND]: "brands",
  [GET_BRAND]: "brands/:id:",
  [UPDATE_BRAND]: "brands/:id:",
  [DELETE_BRAND]: "brands/:id:",
  [GET_CLIENTS_BY_AGENCY]: "agencies/:agency:/clients"
};
const orders = {
  [GET_ORDER_PRIORITIES]: "order-priorities",
  [GET_ORDERS]: "orders?:query:",
  [CREATE_ORDER]: "orders",
  [GET_ORDER]: "orders/:id:",
  [UPDATE_ORDER]: "orders/:id:",
  [UPDATE_ORDER_META]: "orders/:id:/meta",
  [DELETE_ORDER]: "orders/:id:/to-trash",
  [REPAIR_ORDER]: "orders/:id:/from-trash",
  [APPROVE_ORDER]: "orders/:id:/approve-:type:",
  [UNAPPROVE_ORDER]: "orders/:id:/unapprove-:type:",
  [EDIT_ORDER_STATUS]: "orders/:id:/submit-to-:type:",
  [SEARCH_ORDERS]: "orders?key=:key:",
  [GET_ORDERS_BY_BRAND]: "brands/:brand:/orders",
  [EDIT_KEEPER_STATUS_FOR_ORDER]: "orders/:id:/keepers/:user:/:verb:",
  [EDIT_WATCHER_STATUS_FOR_ORDER]: "orders/:id:/watchers/:user:/:verb:",
  //
  [UPLOAD_ORDER_FILE]: "orders/:id:/files",
  [GET_ORDER_FILES]: "orders/:id:/files",
  [DOWNLOAD_ORDER_FILE]: "files/:id:",
  [DELETE_ORDER_FILE]: "files/:id:",
  //
  [GET_ORDER_STATUSES]: "order-statuses",
  [SET_ORDER_STATUS]: "orders/:id:/set-status",
  [GET_DEMOGRAPHY]: "demography",
  //
  [GET_PRICE]: "orders/:id:/:recalcType:-price?:originalType:_price=:value:", //   base-price | retail-price
  [DOWNLOAD_ORDER_XLS]: "orders/:id:/getFlightsFile",
  [CHECK_EXIST_XLS]: "orders/:id:/existsFlightsFile",
  [GENERATE_XLS]: "calc/:id:/generateFlights",
  //
  [UPLOAD_XLS_MANUAL]: "orders/:id:/postFlightsFile",
  [DOWNLOAD_XLS_EXAMPLE]: "flight-file-example",
  [GET_FORMULAS]: "calc/formulas"
};

export default {
  ...auth,
  ...users,
  ...agencies,
  ...clients,
  ...brands,
  ...roles,
  ...orders,
  ...helpers
};
