//roles store namespace

/**
 * replaceWords
 * @param ROLE
 * @param Role
 * @param ROLES
 * @param roles
 * @param Roles

 */

import { GET_ROLES, GET_ROLE } from "../const/roles";
import { HTTP_GET } from "../const/request";

const roles = {
  state: () => ({
    types: [],
    currentRole: {}
  }),
  actions: {
    async [GET_ROLES]({ dispatch }) {
      return await dispatch(
        HTTP_GET,
        { method: GET_ROLES, namespace: "Roles" },
        { root: true }
      );
    },
    async [GET_ROLE]({ dispatch }, { id }) {
      return await dispatch(
        HTTP_GET,
        {
          method: GET_ROLE,
          params: { id },
          namespace: "Roles"
        },
        { root: true }
      );
    }
  },
  mutations: {
    [GET_ROLES](state, { data }) {
      state.types = Object.values(data);
    },
    [GET_ROLE](state, { data }) {
      state.currentRole = data;
    }
  },
  getters: {
    getRoles: state => state.types,
    getRolesIdsByNames: state => (...names) => {
      names = names.flat();
      return state.types
        .filter(role => names.includes(role.name))
        .map(role => role.id);
    }
    // getSomeModuleValue: state => state.someModuleValue
  },
  namespaced: true
};
export default roles;
