<template>
  <v-container class="fill-height " fluid="">
    <v-row align="center" justify="center">
      <v-col align="center" justify="center">
        <v-card class="elevation-0 d-flex" height="585" max-width="380px">
          <v-row align="center" class=" mx-2" justify="center">
            <v-col align="center" justify="center">
              <v-img
                class="ma-3"
                style="max-width:145px"
                src="@/assets/square-logo.svg"
              ></v-img>
              <v-card-title class="justify-center">
                <h3>Log in to Booking service</h3>
                <p>Введите email и пароль</p>
              </v-card-title>
              <v-card-text>
                <v-form ref="form" v-model="valid" lazy-validation>
                  <v-text-field
                    data-cy="email"
                    lazy-validation
                    outlined
                    dense
                    color="secondary"
                    label="Email"
                    :rules="rule.email"
                    v-model="form.email"
                    hide-details="auto"
                    type="text"
                  ></v-text-field>
                  <router-link
                    class="reset-link mt-2"
                    :to="{ name: 'resetPassword' }"
                    >Забыли пароль?</router-link
                  >
                  <v-text-field
                    outlined
                    dense
                    data-cy="password"
                    label="Пароль"
                    v-model="form.password"
                    @keypress.enter="enterToAccount"
                    :rules="rule.password"
                    color="secondary"
                    name="password"
                    :type="viewPass ? 'text' : 'password'"
                    :append-icon="viewPass ? 'mdi-eye' : 'mdi-eye-off'"
                    @click:append="viewPass = !viewPass"
                    hide-details="auto"
                  ></v-text-field>
                </v-form>
              </v-card-text>
              <v-card-actions class="px-4">
                <v-row>
                  <v-col cols="12">
                    <v-btn
                      rounded
                      color="primary"
                      depressed
                      width="70%"
                      :loading="isLoginLoading"
                      @click="enterToAccount"
                      :disabled="!valid"
                      data-cy="login"
                      >Войти</v-btn
                    >
                  </v-col>
                </v-row>
              </v-card-actions>
            </v-col>
          </v-row>
        </v-card>
      </v-col>
    </v-row>
  </v-container>
</template>
<script>
import dbg from "@/plugins/dbg";
import validation from "@/mixins/validationRules";
import requestControl from "@/mixins/requestControl";
import { AUTH_LOGIN } from "../../../store/const/auth";
import { mapActions } from "vuex";
export default {
  mixins: [validation, requestControl],

  data() {
    return {
      form: {
        email: "",
        password: ""
      },
      serverErrors: {
        email: "",
        password: ""
      },
      viewPass: false,
      valid: true,
      isBlockButton: window.innerWidth >= 600,
      lazy: true,
      enterButtonLoading: false
    };
  },
  computed: {
    isLoginLoading() {
      return this.loading(AUTH_LOGIN) === "loading";
    }
  },
  methods: {
    ...mapActions("Auth", {
      login: AUTH_LOGIN
    }),
    async enterToAccount() {
      const loginForm = this.$refs.form;
      const isValid = loginForm.validate();
      if (isValid) {
        await this.login(this.form);
        if (this.checkRequestOnError(AUTH_LOGIN)) {
          this.$notify({
            type: "succ",
            text: "Добро пожаловать"
          });
          this.$router.push({ name: "reservation" });
        }
      } else {
        dbg("1error", loginForm);
      }
    }
  }
};
</script>
