//someEntity store namespace
// import { HTTP_GET, HTTP_POST } from "../const/request";
// import { someModule } from "../const/module";

const someEntity = {
  state: () => ({
    navigationTree: null
  }),
  actions: {},
  mutations: {
    setNavigationTree(state, obj) {
      state.navigationTree = obj;
    }
  },
  getters: {
    getNavigationTree: state => state.navigationTree
  },
  namespaced: true
};
export default someEntity;
