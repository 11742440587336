//users store namespace
import {
  CREATE_USER,
  DELETE_USER,
  GET_USERS,
  GET_USER,
  UPDATE_USER,
  REPAIR_USER,
  GET_USERS_TO_KEEPERS
} from "../const/users";

import { HTTP_POST, HTTP_GET, HTTP_DELETE, HTTP_PATCH } from "../const/request";

const getIds = entities => {
  return entities.map(entity => entity.id);
};

const users = {
  state: () => ({
    users: [],
    // current user (Не юзер под котором залогинен, а юзер над которым совершается действие)
    currentUser: {}
  }),
  actions: {
    async [GET_USERS]({ dispatch }) {
      return await dispatch(
        HTTP_GET,
        { method: GET_USERS, withTrashed: true, namespace: "Users" },
        { root: true }
      );
    },
    async [GET_USERS_TO_KEEPERS]({ dispatch }, { id }) {
      return await dispatch(
        HTTP_GET,
        {
          method: GET_USERS_TO_KEEPERS,
          withTrashed: true,
          namespace: "Users",
          replace: { id }
        },
        { root: true }
      );
    },
    async [REPAIR_USER]({ dispatch }, { id }) {
      return await dispatch(
        HTTP_PATCH,
        { method: REPAIR_USER, namespace: "Users", params: { id } },

        { root: true }
      );
    },
    async [CREATE_USER]({ dispatch }, data) {
      return await dispatch(
        HTTP_POST,
        {
          method: CREATE_USER,
          body: data,
          no_commit: true,
          namespace: "Users"
        },
        { root: true }
      );
    },
    async [GET_USER]({ dispatch }, { id }) {
      return await dispatch(
        HTTP_GET,
        {
          method: GET_USER,
          replace: { id },
          namespace: "Users"
        },
        { root: true }
      );
    },
    async [DELETE_USER]({ dispatch }, { id }) {
      return await dispatch(
        HTTP_DELETE,
        {
          method: DELETE_USER,
          params: { id },
          no_commit: true,
          namespace: "Users"
        },
        { root: true }
      );
    },
    async [UPDATE_USER]({ dispatch }, { id, data }) {
      return await dispatch(
        HTTP_PATCH,
        {
          method: UPDATE_USER,
          params: { id },
          body: data,
          no_commit: true,
          namespace: "Users"
        },
        { root: true }
      );
    }
  },
  mutations: {
    [GET_USERS](state, { data }) {
      state.users = data;
    },
    [GET_USERS_TO_KEEPERS](state, { data }) {
      state.users = data;
    },
    [GET_USER](state, { data }) {
      state.currentUser = data;
    }
  },
  getters: {
    getCurrentUser: state => {
      let {
        email,
        role,
        agencies,
        clients,
        name,
        is_active
      } = state.currentUser;
      if (email && role)
        return {
          email,
          role: role.id,
          agencies: getIds(agencies),
          clients: getIds(clients),
          name,
          is_active
        };
      else return {};
    },
    getAccountUsers: state =>
      state.users.filter(user => user.type.id === "Account"),
    getAllClientUsers: state =>
      state.users.filter(user => user.type.id === "Manager"),
    getRootUsers: state => state.users.filter(user => user.type.id === "Root"),
    getTraderUsers: state =>
      state.users.filter(user => user.type.id === "Trader")
  },
  namespaced: true
};
export default users;
