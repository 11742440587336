export const HTTP_GET = "HTTP_GET";
export const HTTP_POST = "HTTP_POST";
export const HTTP_POST_FORM_DATA = "HTTP_POST_FORM_DATA";
export const HTTP_PUT = "HTTP_PUT";
export const HTTP_DELETE = "HTTP_DELETE";
export const HTTP_PATCH = "HTTP_PATCH";
export const HEALTH_CHECK = "HEALTH_CHECK";
export const PAGINATION = "PAGINATION";
export const LOADING = "LOADING";
export const ERROR = "ERROR";
export const REMOVE_ERROR = "REMOVE_ERROR";
export const CANCEL = "CANCEL";
