export const GET_USERS = "GET_USERS";
export const GET_FILTERED_USERS = "GET_FILTERED_USERS";
export const CREATE_USER = "CREATE_USER";
export const GET_USER = "GET_USER";
export const DELETE_USER = "DELETE_USER";
export const BLOCK_USER = "BLOCK_USER";
export const UNBLOCK_USER = "UNBLOCK_USER";
export const UPDATE_USER = "UPDATE_USER";
export const REPAIR_USER = "REPAIR_USER";
export const GET_USERS_TO_KEEPERS = "GET_USERS_TO_KEEPERS";
