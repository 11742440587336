import Vue from "vue";
import Vuex from "vuex";

import request from "./modules/request";
import auth from "./modules/auth";
import liteCalc from "./modules/liteCalc";
import agencies from "./modules/agencies";
import users from "./modules/users";
import clients from "./modules/clients";
import brands from "./modules/brands";
import roles from "./modules/roles";
import helpers from "./modules/helpers";
import orders from "./modules/orders";
import reservation from "./modules/reservation";
// import someEntity from "./modules/someEntity";
Vue.use(Vuex);

export default new Vuex.Store({
  namespaced: true,
  modules: {
    Auth: auth,
    Request: request,
    LiteCalc: liteCalc,
    Agencies: agencies,
    Users: users,
    Clients: clients,
    Brands: brands,
    Roles: roles,
    Orders: orders,
    Helpers: helpers,
    Reservation: reservation
    // SomeEntity: someEntity
  },
  state: {
    // content: "", // notifier var
    // color: "", // notifier var
    // iconColor: "", // notifier var
    // subMessage: "", // notifier var
  },
  mutations: {
    // showMessage(state, payload) {
    //   // notify handler
    //   state.content = payload.content ?? payload;
    //   state.color = payload.color ?? "infoPopup";
    //   state.iconColor = payload.iconColor ?? "info";
    //   state.subMessage = payload.subMessage ?? "";
    // },
  },
  getters: {},
  actions: {
    // universalPromiseAll(_, { promiseFunc, entities, params }) {
    //   const promiseArray = new Array();
    //   for (const id in entities) {
    //     promiseArray.push(promiseFunc(entities[id], params));
    //   }
    //   return Promise.allSettled(promiseArray);
    // },
  }
});
