<template>
  <v-main>
    <router-view></router-view>
  </v-main>
</template>
<script>
export default {
  data() {
    return {};
  }
};
</script>
