//agencies store namespace

/**
 * replaceWords
 * @param AGENCY
 * @param Agency
 * @param AGENCIES
 * @param agencies
 * @param Agencies

 */

import {
  CREATE_AGENCY,
  DELETE_AGENCY,
  GET_AGENCIES,
  GET_AGENCY,
  UPDATE_AGENCY
} from "../const/agencies";
import { HTTP_POST, HTTP_GET, HTTP_DELETE, HTTP_PATCH } from "../const/request";

const agencies = {
  state: () => ({
    agencies: [],
    currentAgency: {}
  }),
  actions: {
    async [GET_AGENCIES]({ dispatch }) {
      return await dispatch(
        HTTP_GET,

        { method: GET_AGENCIES, withTrashed: true, namespace: "Agencies" },
        { root: true }
      );
    },
    async [CREATE_AGENCY]({ dispatch }, data) {
      return await dispatch(
        HTTP_POST,
        {
          method: CREATE_AGENCY,
          body: data,

          no_commit: true,
          namespace: "Agencies"
        },
        { root: true }
      );
    },
    async [GET_AGENCY]({ dispatch }, { id }) {
      return await dispatch(
        HTTP_GET,
        {
          method: GET_AGENCY,
          params: { id },
          namespace: "Agencies"
        },
        { root: true }
      );
    },
    async [DELETE_AGENCY]({ dispatch }, { id }) {
      return await dispatch(
        HTTP_DELETE,
        {
          method: DELETE_AGENCY,
          params: { id },
          no_commit: true,
          namespace: "Agencies"
        },
        { root: true }
      );
    },
    async [UPDATE_AGENCY]({ dispatch }, { id, data }) {
      return await dispatch(
        HTTP_PATCH,
        {
          method: UPDATE_AGENCY,
          params: { id },
          body: data,
          no_commit: true,
          namespace: "Agencies"
        },
        { root: true }
      );
    }
  },
  mutations: {
    [GET_AGENCIES](state, { data }) {
      state.agencies = data;
    },
    [GET_AGENCY](state, { data }) {
      state.currentAgency = data;
    }
  },
  getters: {
    getOnlyAgencies: state =>
      state.agencies.map(({ id, name }) => {
        return { id, name };
      }),
    getAgenciesName: state => state.agencies.map(({ name }) => name)
    // getSomeModuleValue: state => state.someModuleValue
  },
  namespaced: true
};
export default agencies;
