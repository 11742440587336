export default {
  data() {
    return {
      rule: {
        email: [
          v => !!v || "Введите почтовый адрес",
          v => /.+@.+\..+/.test(v) || "Почтовый адрес должен быть валиден"
        ],
        password: [
          v => !!v || "Пароль не может быть пустым",
          v => v.length > 1 || "Пароль не может содержать меньше 2 символов"
        ],
        regularField: [
          v => v.length > 1 || "Это поле не может содержать меньше 2 символов"
        ],
        minZero: [v => v >= 0 || "Это поле не может быть меньше 0"],
        required: [v => !!v || v === 0 || "Это поле обязательно для заполнения"]
      }
    };
  }
};
