//tag store namespace
import Vue from "vue";
import JSONbig from "json-bigint";
import {
  CREATE_TAG,
  DELETE_TAG,
  GET_TAGS,
  GET_TAG,
  UPDATE_TAG,
  GET_GEOTAXONOMY,
  SEND_BUG_REPORT
} from "../const/helpers";
import { HTTP_POST, HTTP_GET, HTTP_DELETE, HTTP_PATCH } from "../const/request";

const helpers = {
  state: () => ({
    geoTaxonomy: {},
    tag: [],
    currentTag: {},
    showTrashedEntities: false
  }),
  actions: {
    async [GET_GEOTAXONOMY]({ commit }) {
      window
        .fetch(Vue.prototype.$baseURL +"geo", {
          method: "GET",
          headers: {
            Accept: "application/json",
            "Content-Type": "application/json",
            Authorization: "Bearer " + window.localStorage.getItem("userToken")
          }
        })

        .then(response => {
          response.text().then(text => {
            commit(
              GET_GEOTAXONOMY,
              JSONbig.parse(text, (key, value) => {
                if (key === "parentID") {
                  return value.toString();
                } else {
                  return value;
                }
              })
            );
          });
        });
    },
    async [GET_TAGS]({ dispatch }) {
      return await dispatch(
        HTTP_GET,
        { method: GET_TAGS, namespace: "Helpers" },
        { root: true }
      );
    },
    async [SEND_BUG_REPORT]({ dispatch }, data) {
      return await dispatch(
        HTTP_POST,
        {
          method: SEND_BUG_REPORT,
          formData: data,
          body: data,
          no_commit: true,
          namespace: "Helpers"
        },
        { root: true }
      );
    },
    async [CREATE_TAG]({ dispatch }, data) {
      return await dispatch(
        HTTP_POST,
        {
          method: CREATE_TAG,
          body: data,
          no_commit: true,
          namespace: "Helpers"
        },
        { root: true }
      );
    },
    async [GET_TAG]({ dispatch }, { id }) {
      return await dispatch(
        HTTP_DELETE,
        {
          method: GET_TAG,
          params: { id },
          namespace: "Helpers"
        },
        { root: true }
      );
    },
    async [DELETE_TAG]({ dispatch }, { id }) {
      return await dispatch(
        HTTP_DELETE,
        {
          method: DELETE_TAG,
          params: { id },
          no_commit: true,
          namespace: "Helpers"
        },
        { root: true }
      );
    },
    async [UPDATE_TAG]({ dispatch }, { id, data }) {
      return await dispatch(
        HTTP_PATCH,
        {
          method: UPDATE_TAG,
          params: { id },
          body: data,
          no_commit: true,
          namespace: "Helpers"
        },
        { root: true }
      );
    }
  },
  mutations: {
    [GET_GEOTAXONOMY](state, data) {
      state.geoTaxonomy = data;
    },
    setShowTrashedEntities(state, boolean) {
      state.showTrashedEntities = boolean;
    },
    [GET_TAGS](state, { data }) {
      state.tag = data;
    },
    [GET_TAG](state, { data }) {
      state.currentTag = data;
    }
  },
  getters: {
    getOnlyTags: state =>
      state.tag.map(({ id, name }) => {
        return { id, name };
      }),
    getTagsName: state => state.tag.map(({ name }) => name)
    // getSomeModuleValue: state => state.someModuleValue
  },
  namespaced: true
};
export default helpers;
