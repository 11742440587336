//clients store namespace

import {
  CREATE_CLIENT,
  DELETE_CLIENT,
  GET_CLIENTS,
  GET_CLIENT,
  UPDATE_CLIENT,
  GET_CLIENTS_BY_AGENCY
} from "../const/clients";
import { HTTP_POST, HTTP_GET, HTTP_DELETE, HTTP_PATCH } from "../const/request";

const clients = {
  state: () => ({
    clients: [],
    currentClient: {},
    clientsMap: {}
  }),
  actions: {
    async [GET_CLIENTS]({ dispatch }) {
      return await dispatch(
        HTTP_GET,
        { method: GET_CLIENTS, withTrashed: true, namespace: "Clients" },
        { root: true }
      );
    },
    async [GET_CLIENTS_BY_AGENCY]({ dispatch }, agency) {
      return await dispatch(
        HTTP_GET,
        {
          method: GET_CLIENTS_BY_AGENCY,
          replace: { agency },
          withTrashed: true,
          params: agency,
          namespace: "Clients"
        },
        { root: true }
      );
    },
    async [CREATE_CLIENT]({ dispatch }, data) {
      return await dispatch(
        HTTP_POST,
        {
          method: CREATE_CLIENT,
          body: data,
          no_commit: true,
          namespace: "Clients"
        },
        { root: true }
      );
    },
    async [GET_CLIENT]({ dispatch }, { id }) {
      return await dispatch(
        HTTP_DELETE,
        {
          method: GET_CLIENT,
          params: { id },
          namespace: "Clients"
        },
        { root: true }
      );
    },
    async [DELETE_CLIENT]({ dispatch }, { id }) {
      return await dispatch(
        HTTP_DELETE,
        {
          method: DELETE_CLIENT,
          params: { id },
          no_commit: true,
          namespace: "Clients"
        },
        { root: true }
      );
    },
    async [UPDATE_CLIENT]({ dispatch }, { id, data }) {
      return await dispatch(
        HTTP_PATCH,
        {
          method: UPDATE_CLIENT,
          params: { id },
          body: data,
          no_commit: true,
          namespace: "Clients"
        },
        { root: true }
      );
    }
  },
  mutations: {
    [GET_CLIENTS](state, { data }) {
      state.clients = data;
    },
    [GET_CLIENTS_BY_AGENCY](state, { data, params: agency }) {
      state.clientsMap = JSON.parse(
        JSON.stringify({
          ...state.clientsMap,
          [agency]: data
        })
      );
      state.clients = data;
    },
    [GET_CLIENT](state, { data }) {
      state.currentAgency = data;
    }
  },
  getters: {
    getClientsName: state => state.clients.map(({ name }) => name),
    getClients: state => state.clients
    // getSomeModuleValue: state => state.someModuleValue
  },
  namespaced: true
};
export default clients;
